'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

$(document).ready(function () {
    $(document).foundation({
        "magellan-expedition": {
            active_class: 'active', // specify the class used for active sections
            threshold: 0, // how many pixels until the magellan bar sticks, 0 = auto
            destination_threshold: 20, // pixels from the top of destination for it to be considered active
            throttle_delay: 50, // calculation throttling to increase framerate
            fixed_top: 55, // top distance in pixels assigend to the fixed element on scroll
            offset_by_height: true // whether to offset the destination by the expedition height. Usually you want this to be true, unless your expedition is on the side.
        }
    });
    Config.template = 'STARTER';
    var starter = new Starter();
});

var Starter = function () {
    function Starter() {
        var _this = this;

        _classCallCheck(this, Starter);

        this.lastScrollTop = 0;
        this.delta = 5;
        this.pubHome = $('#campaign-home');
        this.placeholders = ["Choisissez une ville", "Choisissez un type"];
        this.scrollMenu();
        this.initSelect();
        this.bindUIActions();
        if (this.pubHome.css('display')) {
            var img = new Image();
            img.onload = function () {
                if (BrowserInfo.isMobile) _this.resizePub();
                _this.pubHome.css({
                    'margin-top': '-' + (_this.pubHome.height() / 2 + 1) + 'px',
                    'display': 'block'
                });
                _this.pubHome.removeClass('hidden');
            };
            img.src = this.pubHome.find('img').attr('src');
        }
        window.addEventListener('scroll', this.scrollMenu);
        window.onresize = function () {
            if (window.innerWidth > 1025) {
                $('.off-canvas-wrap').removeClass('move-right move-left');
            }
        };
    }

    _createClass(Starter, [{
        key: 'bindUIActions',
        value: function bindUIActions() {
            $('.ui-cookies').click(function () {
                $('#ui-id-cookies').toggle(800);
            });
            $("#submit-search-st3").on("click", function (e) {
                e.preventDefault();
                $("#form-st3").submit();
            });
        }
    }, {
        key: 'resizePub',
        value: function resizePub() {
            $('.ui-ad-image').css('max-height', '90vh');
            $('#close-ad').css({
                'right': '-13px',
                'top': '-13px',
                'width': '25px',
                'height': '25px'
            });
            $('#close-ad i').css({
                'font-size': '1em',
                'top': '0'
            });
        }
    }, {
        key: 'scrollMenu',
        value: function scrollMenu() {
            var st = $('body').scrollTop(),
                navbarHeight = $('header').outerHeight();
            if (Math.abs(this.lastScrollTop - st) <= this.delta) return;
            if (st > this.lastScrollTop && st > navbarHeight) {
                $('header').removeClass('nav-down').addClass('nav-up');
            } else if (st < this.lastScrollTop && st < navbarHeight) {
                $('header').removeClass('nav-up').addClass('nav-down');
            }
            this.lastScrollTop = st;
        }
    }, {
        key: 'initSelect',
        value: function initSelect() {
            var SELECTS = [{ 'select': $("select.villemoteur"), 'placeholder': this.placeholders[0] }, { 'select': $("select.villemoteur1"), 'placeholder': this.placeholders[0] }, { 'select': $("select.moteurlightvillebien"), 'placeholder': this.placeholders[0] }, { 'select': $("select.typemoteur"), 'placeholder': this.placeholders[1] }, { 'select': $("select.typemoteur1"), 'placeholder': this.placeholders[1] }],
                i = 0;
            for (i = 0; i < SELECTS.length; i++) {
                SELECTS[i]['select'].select2({
                    placeholder: SELECTS[i]['placeholder'],
                    dropdownCssClass: "bigdrop",
                    allowClear: true
                });
            }
        }
    }]);

    return Starter;
}();
